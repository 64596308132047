<template>
    <div class="scheme-container">
        <h3>{{ $t("Together.move_tog") }}</h3>
        <div class="buttonContainer">
            <a href="https://docs.google.com/presentation/d/1lA7-cipWAw7mJvg-ZmJoq7FW91oD659y/edit?usp=sharing&ouid=106683429722487344485&rtpof=true&sd=true">
                <button class="button__scheme">
                    {{$t('Together.read')}}
                </button>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .start-bg {
        margin-top : 3em;
    }

    .scheme-container {
    margin-top: 75px;
    padding: 20px 100px 0;
    width: 100%;
    
    @media screen and (max-width: 720px) {
      flex-direction: column;
      padding: 20px 50px 0;
    }


    h3 {
      font-size: 22px;
      line-height: 28px;
      font-family: "Lato", sans-serif;
      color: #707070;
    }

    .buttonContainer {
      margin-top: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;

      .button__scheme {
        cursor: pointer;
        padding: 15px 10px;
        width: 250px;
        border-radius: 4px;
        font-size: 16px;
        background-color: #1900ff;
        border: 1px solid #1900ff;
        color: #fff;
        transition: 0.3s;

        &:hover {
          background-color: white;
          border: 1px solid #1900ff;
          color: #1900ff;
        }
      }

      @media screen and (max-width: 720px) {
        flex-direction: column;
      }
    }
}

</style>